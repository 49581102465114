<template>
    <v-skeleton-loader type="article" tile :loading="documentDetailsLoading">
        <v-card id="documentDetails" class="pa-4" tile flat>
            <template v-for="(header, i) in headers">
                <v-row :key="'header-' + i" class="documentDetailsRow">
                    <v-col class="documentDetailsTitle">{{$t('message.' + header.label)}}</v-col>
                    <v-col v-if="header.format == 'special'" class="pa-2 documentDetailsValue">
                        <span v-if="header.field == 'status'" v-html="getStatus(item[header.field])"></span>
                        <span v-else-if="header.field == 'doc_payment_status'" v-html="getPaymentStatus(item[header.field], item['doc_payment_expiry'])"></span>
                        <span v-else>{{ item[header.field] }}</span>
                    </v-col>
                    <v-col v-else-if="header.format == 'bool'" class="pa-2 documentDetailsValue">
                        <v-simple-checkbox :input-value="item[header.field]" value disabled></v-simple-checkbox>
                    </v-col>
                    <v-col v-else-if="header.format == 'number'" class="pa-2 documentDetailsValue">
                        {{ item[header.field] | formatNumber(header.format_show) }}
                    </v-col>
                    <v-col v-else-if="header.format == 'amount'" class="pa-2 documentDetailsValue">
                        {{ item[header.field] | formatAmount(header.format_show, documentInfo.currency_sigle) }}
                    </v-col>
                    <v-col v-else-if="header.format == 'date'" class="pa-2 documentDetailsValue">
                        {{ item[header.field] | formatDate(header.format_show, 'Non disponibile') }}
                    </v-col>
                    <v-col v-else class="pa-2 documentDetailsValue">
                        {{ item[header.field] }}
                    </v-col>
                </v-row>
                <v-divider v-if="showDivider(i)" :key="'divider-' + i" class="documentDetailsDivider"></v-divider>
            </template>
        </v-card> 
    </v-skeleton-loader>
</template>

<script>
import { services } from "../scripts/services/serviceBuilder";
export default {
    name: 'documentDetails',
    data() {
        return {
           headers: [],
           documentDetailsLoading : true
        }
    },
    props: {
        type: null,
        item: Object,
        documentInfo: null
    },
    methods: {
        getPaymentStatus: function (value, docPaymentExpiry) {
            switch (value) {
                case '1':
                    return '<span class="documentDetails_paymentStatus_pay"></span>';
                case '2':
                    return '<span class="documentDetails_paymentStatus_verify"></span>';
                default:
                    if (docPaymentExpiry) {
                        var dpe = new Date(docPaymentExpiry);
                        var now = new Date();
                        if (dpe >= now)
                            return '<span class="documentDetails_paymentStatus_expiring"></span>';
                        else
                            return '<span class="documentDetails_paymentStatus_notpay"></span>';
                    }
                    else {
                        return '<span class="documentDetails_paymentStatus_notpay"></span>';
                    }
            }
        },
        getStatus: function (value) {
            var that = this;

            switch (value) {
                case 0:
                    if (that.documentInfo.contracts_customers_icons == '1') {
                        return '<span class="documentDetails_status_notActive_icon"><i aria-hidden="true" id="errorIcon" class="v-icon notranslate mdi mdi-close-circle-outline theme--light"></i>&nbsp;</span>';
                    }
                    else {
                        return '<span class="documentDetails_status_notActive"></span>';
                    }
                case 1:
                    if (that.documentInfo.contracts_customers_icons == '1') {
                        return '<span class="documentDetails_status_active_icon"><i aria-hidden="true" id="errorIcon" class="v-icon notranslate mdi mdi-check-circle-outline theme--light"></i>&nbsp;</span>';
                    }
                    else {
                        return '<span class="documentDetails_status_active"></span>';
                    }
                case 2:
                    if (that.documentInfo.contracts_customers_icons == '1') {
                        return '<span class="documentDetails_status_suspended_icon"><i aria-hidden="true" id="errorIcon" class="v-icon notranslate mdi mdi-alert-circle-outline theme--light"></i>&nbsp;</span>';
                    }
                    else {
                        return '<span class="documentDetails_status_suspended"></span>';
                    }
                default:
                    return '';
            }
        },
        showDivider: function (index) {
            if(this.headers && this.headers.length > 0 ) {
                if(index != this.headers.length - 1 && 
                   this.headers[index].details_box_number != this.headers[index + 1].details_box_number)
                return true;
            }

            return false;
        }
    },
    created() {
        var that = this;

        that.documentDetailsLoading = true;
        services.apiCallerDocument.listDetailHeaders(that.type)
            .then((response) => {
                that.headers = response.data;
            })
            .catch((error) => {
                that.$refs.mySnackbar.open(that.$t("message.document_list_error"));
                console.log(error);
            })
            .finally(() => {
                that.documentDetailsLoading = false
            });
    }
}
</script>