<template>
    <v-container id="documents" class="pt-8 pb-8 pa-0">
        <BannerPage v-if="documentInfo" 
            icon="mdi-file-document-multiple " 
            :title='$t("message.invoices_list_header")'></BannerPage>
        <v-skeleton-loader type="article" tile :loading="documentPageLoading">
            <v-expansion-panels flat expanded v-model="panel">
                <v-expansion-panel class="ma-2 border" v-for="(itemDoc, i) in items" :key="i">
                    <v-expansion-panel-header class="documentsPanelTitle" @click="getRows(itemDoc,i)">
                        {{ $t('message.' + itemDoc.section.name) }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-skeleton-loader type="table" tile :loading="rowsLoading">
                            <v-card v-if="itemDoc.entries && itemDoc.entries.length > 0" flat>
                                <v-card-title>
                                    <v-combobox v-model="itemDoc.selectedYears" 
                                        :items="itemDoc.years" 
                                        :label='$t("message.document_year_of_issue")'
                                        multiple chips
                                        v-on:change="filterEntries(i)"
                                        :hide-details="true"/>
                                    <v-spacer/>
                                    <v-text-field v-model="search" append-icon="mdi-magnify"
                                        :label='$t("message.document_find_label")' 
                                        single-line  hide-details />
                                </v-card-title>
                                <v-data-table
                                    :headers="itemDoc.headers"
                                    :items="filteredEntries[i]"
                                    :search="search"
                                    :footer-props="{'items-per-page-text':$t('message.document_num_per_page')}"
                                    :single-expand="true"
                                    :expanded.sync="expanded"
                                    :show-expand="documentInfo.invoices_list_details == 1"
                                    class="documentsDataGrid">

                                    <template v-slot:item="{ item, headers, expand, isExpanded }">
                                        <tr>
                                            <td v-for="(hdr, j) in headers" :key="'item-' + j" v-bind:style="{ 'text-align': hdr.align }">
                                                <template v-if="hdr.value == 'data-table-expand'">
                                                    <v-icon :class="['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': isExpanded }]" 
                                                        @click.stop="expand(!isExpanded)">$expand</v-icon>
                                                </template>
                                                <template v-else-if="hdr.value == 'detail'">
                                                    <v-btn id="btnPdf" icon @click="downloadFile(item)"
                                                        :class="{ 'newFile': item.new, 'unreadFile': !item.read }">
                                                        <v-icon id="pdfIcon"> mdi-file-pdf </v-icon>
                                                        {{item.new ? $t('message.document_new_file_label') : ''}}
                                                    </v-btn>

                                                    <v-btn v-if="item.landing_page == 1" id="btnLandingPage" icon @click="gotoBollettaInterattiva(item)" >
                                                        <v-icon id="landingPageIcon"> mdi-cellphone </v-icon>
                                                    </v-btn>

                                                </template>
                                                <template v-else-if="hdr.format == 'special'">
                                                    <span v-if="hdr.value == 'status'" v-html="getStatus(item[hdr.value])"></span>
                                                    <span v-else-if="hdr.value == 'doc_payment_status'" v-html="getPaymentStatus(item[hdr.value], item.doc_payment_expiry)"></span>
                                                    <span v-else>{{ item[hdr.value] }}</span>
                                                </template>
                                                <span v-else-if="hdr.format == 'bool'">
                                                    <v-simple-checkbox :input-value="item[hdr.value]" value disabled></v-simple-checkbox>
                                                </span>
                                                <template v-else-if="hdr.format == 'number'">
                                                    {{ item[hdr.value] | formatNumber(hdr.format_show) }}
                                                </template>
                                                <span v-else-if="hdr.format == 'amount'">
                                                    {{ item[hdr.value] | formatAmount(hdr.format_show, documentInfo.currency_sigle) }}
                                                </span>
                                                <template v-else-if="hdr.format == 'date'">
                                                    {{ item[hdr.value] | formatDate(hdr.format_show) }}
                                                </template>
                                                <template v-else>{{ item[hdr.value] }}</template>
                                            </td>
                                        </tr>
                                    </template>

                                    <template v-if="documentInfo.invoices_list_details == 1" v-slot:expanded-item="{headers, item}">
                                        <td style="padding:0px; box-shadow:none" :colspan="headers.length">
                                            <DocumentDetails :document-info="documentInfo" :type="item.document_type" :item="item"></DocumentDetails>
                                        </td> 
                                    </template>
                                </v-data-table>
                            </v-card>
                            <span v-else class="documentsListEmpty" v-html="$t('message.invoices_list_no_doc_text')"></span>
                        </v-skeleton-loader>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-skeleton-loader>

        <Snackbar ref="mySnackbar"></Snackbar> 
    </v-container>
</template>

<script>
import { services } from "../scripts/services/serviceBuilder";
import DocumentDetails from './DocumentDetails';
import Snackbar from './Snackbar.vue';
import BannerPage from './BannerPage.vue';

export default {
    name: 'documents',
    components: {
        DocumentDetails,
        Snackbar,
        BannerPage
	},
    data () {
        return {
            search: '',
            documentInfo: null,
            documentPageLoading: true,
            rowsLoading: true,
            items: [],
            expanded: [],
            filteredEntries: [],
            headerAlign: ['center', 'start', 'end'],
            panel: null
        }
    },
    methods: {
        filterEntries: function (index) {
            this.filteredEntries[index] = [];
            var result;
            if(this.items[index].entries)
            {
                if(this.items[index].selectedYears.length > 0) {
                    result = this.items[index].entries.filter(entry => this.items[index].selectedYears.includes(entry.year));
                }
                else {
                    result = this.items[index].entries;
                }
                
                this.filteredEntries.splice(index, 1, result);
            }           
        },
        setItems: function (data) {
            var that = this;

            data.forEach(element => {
                var headersFromDto = [];

                element.header.forEach(x => {
                    headersFromDto.push({
                        text: x.field == 'detail' ? this.$t('message.invoices_detail') : this.$t('message.' + x.label),
                        align: x.alignment ? that.headerAlign[x.alignment] : 'center',
                        value: x.field,
                        sortable : x.sortable == 1 ? true : false,
                        class: 'secondaryColorBg white--text',
                        format: x.format,
                        format_show: x.format_show
                    });
                });

                headersFromDto[headersFromDto.findIndex(x => x.value == 'detail')].width = '1%';

                if (that.documentInfo.invoices_list_details == 1) {
                    headersFromDto.push({
                        text: '',
                        align: 'center',
                        value: 'data-table-expand',
                        sortable : false,
                        class: 'secondaryColorBg white--text',
                        format: null,
                        format_show: null
                    });
                }

                that.items.push({
                    section: element.section,
                    headers: that.filterHeaders(headersFromDto),
                    entries: []
                });    
            });
        },
        filterHeaders: function(headers) {
            headers.forEach(header => {
                if(header.value == "detail") {
                    header.width = 105;
                }
            })
            return headers;
        },
        getRows: function(item, index) {
            var that = this;
            that.expanded = [];

            that.rowsLoading = true;
            services.apiCallerDocument.listDocumentsInView(item.section.id, that.$cod_client.value)
                .then((response) => {
                    that.items[index].entries = response.data.entries;
                    if (response.data.tabs && response.data.tabs.length > 0)
                    {
                        that.items[index].years = response.data.tabs;
                        that.items[index].selectedYears = [response.data.tabs[0]];
                        that.filterEntries(index); 
                    }                       
                })
                .catch((error) => {
                that.$refs.mySnackbar.open(that.$t("message.document_list_error"));
                    console.log(error);
                })
                .finally(() => {
                    that.rowsLoading = false;
                });           
        },
        getPaymentStatus: function (value, docPaymentExpiry) {
            switch (value) {
                case '1':
                    return '<span class="documents_paymentStatus_pay">' + this.$t("message.document_payment_status_paid") + '</span>';
                case '2':
                    return '<span class="documents_paymentStatus_verify">' + this.$t("message.document_payment_status_verify") + '</span>';
                default:
                    if (docPaymentExpiry) {
                        var dpe = new Date(docPaymentExpiry);
                        var now = new Date();
                        if (dpe >= now)
                            return '<span class="documents_paymentStatus_expiring">'+ this.$t("message.document_payment_status_expiring") + '</span>';
                        else
                            return '<span class="documents_paymentStatus_notpay">'+ this.$t("message.document_payment_status_notpay") + '</span>';
                    }
                    else {
                        return '<span class="documents_paymentStatus_notpay">'+ this.$t("message.document_payment_status_notpay") + '</span>';
                    }
            }
        },
        getStatus: function (value) {
            switch (value) {
                case 0:
                    if (this.documentInfo.contracts_customers_icons == '1') {
                        return '<span class="documents_status_notActive_icon"><i aria-hidden="true" id="errorIcon" class="v-icon notranslate mdi mdi-close-circle-outline theme--light"></i>'+ this.$t("message.document_status_not_active") + '</span>';
                    }
                    else {
                        return '<span class="documents_status_notActive"></span>';
                    }
                case 1:
                    if (this.documentInfo.contracts_customers_icons == '1') {
                        return '<span class="documents_status_active_icon"><i aria-hidden="true" id="errorIcon" class="v-icon notranslate mdi mdi-check-circle-outline theme--light"></i>'+ this.$t("message.document_status_active") + '</span>';
                    }
                    else {
                        return '<span class="documents_status_active">'+ this.$t("message.document_status_active") + '</span>';
                    }
                case 2:
                    if (this.documentInfo.contracts_customers_icons == '1') {
                        return '<span class="documents_status_suspended_icon"><i aria-hidden="true" id="errorIcon" class="v-icon notranslate mdi mdi-alert-circle-outline theme--light"></i>'+ this.$t("message.document_status_suspended") + '</span>';
                    }
                    else {
                        return '<span class="documents_status_suspended">'+ this.$t("message.document_status_suspended") + '</span>';
                    }
                default:
                    return '';
            }
        },
        downloadFile: function(item) {
            var that = this;

            services.apiCallerMedia.download(item.detail)
                .then(response =>  {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'file.pdf');
                    document.body.appendChild(link);
                    link.click();
                })               
                .catch(error => {
                    console.log(error);
                that.$refs.mySnackbar.open(that.$t("message.document_list_error"));
                });
        },
        gotoBollettaInterattiva: function(item){
            window.open("/bollettainterattiva?id=" + item.crypto + "&lang=" + this.$locale.value, '_blank');
        },
        loadData: function () {
            var that = this;

            that.documentPageLoading = true;

            that.items = [];
            that.documentInfo = [];
            that.filteredEntries = [];

            var requests = [];
            requests.push(services.apiCallerInvoice.getInvocesInfo());
            requests.push(services.apiCallerDocument.listHeaders(that.$cod_client.value));

            Promise.all(requests)
                .then(responses => {
                    that.documentInfo = responses[0].data;
                    that.setItems(responses[1].data);
                    that.filteredEntries = new Array(responses[1].data.length)

                    if(this.refresh)
                    {
                        this.refresh = false;
                        that.panel = null;
                    }

                    if(that.documentInfo.invoices_table_open_by_default == 1)
                    {
                        that.panel = 0;
                        that.getRows(that.items[0], 0);
                    }
                    
                    
                })
                .catch(error => {
                    console.log(error);
                that.$refs.mySnackbar.open(that.$t("message.document_list_error"));
                })
                .finally(() => {
                    that.documentPageLoading = false;
                });
        },
        reloadData: function(){
            this.refresh = true;
            this.loadData();
        }
    },
    created: function () {
        if(this.$cod_client.value) {
            this.loadData();
        }
        
        this.$root.$on("localeChanged", this.reloadData);
        this.$root.$on("codClientChanged", this.reloadData);
    }
}
</script>


